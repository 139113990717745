.loader-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature {
  height: 100%;
  margin: auto;
  padding: 0px;
  width: 100%;
}

.feature #instructions {
  font-weight: bold;
  text-align: center;
  padding: 70px 0;
}

.vyking-sneaker-window-wrapper {
  background-color: rgba(255, 255, 255, 0.8);
  height: 100%;
  left: 0;
  overflow: hidden;
  top: 0;
  transition: 0.5s;
  width: 100%;
  z-index: 1;
}

@media screen and (orientation: portrait) and (min-width: 680px) and (min-height: 800px) {
  .feature {
    height: 640px;
    margin-top: 60px;
    position: relative;
    width: 360px;
  }
}

@media screen and (orientation: landscape) and (min-width: 800px) and (min-height: 680px) {
  .feature {
    height: 360px;
    margin-top: 60px;
    position: relative;
    width: 640px;
  }
}

.vyking-sneaker-window-contents {
  height: 100%;
  margin: 0px;
  padding: 0px;
  width: 100%;
  position: relative;
}

.h-full {
  height: 100%;
}

.vyking-sneaker-window-contents .vyking-sneaker-window {
  border: 0px;
  height: 100%;
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.vyking-sneaker-window-contents .photobtn {
  height: 50px;
  position: absolute;
  top: 10%;
  cursor: pointer;
  right: 5%;
  width: 50px;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}
