.catalogue {
  bottom: 0;
  display: none;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 12px;
  overflow-x: auto;
  position: absolute;
  width: 100%;
}

.catalogue figure {
  margin: auto;
  padding: 5px;
  text-align: center;
  width: 100px;
}

.catalogue figure img {
  opacity: var(--catalogue-figure-opacity);
  width: 100px;
}

.catalogue figure figcaption {
  color: white;
}

@media only screen and (min-width: 768px) {
  .catalogue figure figcaption {
    color: black;
  }
}

@media screen and (orientation: landscape) and (min-width: 800px) and (min-height: 680px) {
  .catalogue {
    display: flex;
  }
}