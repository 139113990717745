.flex-col {
  flex-direction: column;
}

.w-full {
  width: 100%;
}

.mt-4 {
  margin-top: 8px;
}

.mr-2 {
  margin-right: 4px;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.camera-actions {
  position: fixed;
  padding: 8px;
  z-index: 10;
  width: 90%;
  margin: 0 auto;
}

@media screen and (orientation: landscape) and (min-width: 800px) and (min-height: 680px) {
  .camera-actions {
    position: static;
    width: 100%;
    padding-bottom: 24px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (orientation: portrait) and (min-width: 680px) and (min-height: 800px) {
  .camera-actions {
    position: static;
    padding-bottom: 16px;
    padding-top: 8px;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}